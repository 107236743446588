// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-not-found-page-tsx": () => import("./../src/templates/not-found-page.tsx" /* webpackChunkName: "component---src-templates-not-found-page-tsx" */),
  "component---src-templates-standard-page-tsx": () => import("./../src/templates/standard-page.tsx" /* webpackChunkName: "component---src-templates-standard-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-tags-index-tsx": () => import("./../src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */)
}

